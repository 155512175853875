import React from "react";
import {fetchDefaultSale} from "Features/Api";
import PageHome from "Screens/Home";
import trackPageview from "../src/Utils/analitics/google";

const Home = ({customData, ...props}) => {
    trackPageview();
    return <PageHome {...props} customData={customData} />;
};

export async function getStaticProps() {
    const [response] = await Promise.all([fetchDefaultSale()]);
    const json = await response.json();

    if (!json) {
        return {
            redirect: {
                destination: '/',
                permanent: false,
            },
        }
    }

    json.data.items = json.data.items.map(item => {
        return {
            id: item.id,
            eventName: item.event_name,
            name: item.name,
            months: item.interval_count,
            price: item.price
        };
    });

    return {
        props: {
            customData: json.data,
        },
        revalidate: 3,
    };
}


export default Home;
